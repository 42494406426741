
import {map} from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { ApiService, UtilityService } from "app/shared/services";
import { Observable } from "rxjs";
import {
  UsersListConfig,
  ListUsers,
  ListRoles,
  ListOrgAccess
} from "app/administration/trendz/trendz-users/_models";
import { HttpParams } from "@angular/common/http";

@Injectable()
export class TrendzUsersService {
  updateSettings(orgPref: any, userId: any) {
    throw new Error("Method not implemented.");
  }

  constructor(
    private apiService: ApiService,
    private utilityService: UtilityService
  ) {}
  getAllUsers(
    config: UsersListConfig
  ): Observable<{ count: number; users: any }> {
    // Convert any filters over to Angular's URLSearchParams
    let params: HttpParams = new HttpParams();
    Object.keys(config.config).forEach(key => {
      params = params.set(key, config.config[key]);
    });
    params = params.set("filter", config.getFilters());

    return this.apiService.get("/trendz/users/", params).pipe(map(data => data));
  }
  getUser(userId: number): Observable<{ count: number; users: ListUsers }> {
    return this.apiService.get("/trendz/users/" + userId);
  }

  getUserRoles(userId: number): Observable<{ count: number; userRoles: any }> {
    return this.apiService.get("/trendz/users/" + userId + "/roles");
  }

  saveUser(user, isCreate): Observable<any> {
    if (isCreate) {
      return this.apiService
        .post("/trendz/users/", { user: user }).pipe(
        map(data => {
          return data;
        }));
    } else if (user.userId) {
      return this.apiService
        .put("/trendz/users/" + user.userId, { user: user }).pipe(
        map(data => {
          return data;
        }));
    }
  }
  saveRole(userRole, isCreate): Observable<any> {
    if (isCreate) {
      return this.apiService
        .post("/trendz/users/" + userRole.userId + "/roles", {
          userRole: userRole
        }).pipe(
        map(data => {
          return data;
        }));
    } else if (userRole.roleUserAsignmntId) {
      return this.apiService
        .put(
          "/trendz/users/" +
            userRole.userId +
            "/roles/" +
            userRole.roleUserAsignmntId,
          { userRole: userRole }
        ).pipe(
        map(data => {
          return data;
        }));
    }
  }

  getOrgAccess(
    userId
  ): Observable<{ count: number; userOrgAccess: ListOrgAccess }> {
    return this.apiService.get("/trendz/users/" + userId + "/org_access");
  }

  createOrgAccess(newOrgAccess): Observable<any> {
    return this.apiService
      .post("/trendz/users/" + newOrgAccess.userId + "/org_access", {
        userOrgAccess: newOrgAccess
      }).pipe(
      map(data => data));
  }

  updateOrgAccess(OrgAccess): Observable<any> {
    return this.apiService
      .put(
        "/trendz/users/" +
          OrgAccess.userId +
          "/org_access/" +
          OrgAccess.orgAccessId,
        { userOrgAccess: OrgAccess }
      ).pipe(
      map(data => data));
  }

  checkPrevilage(uniqueId): Observable<any> {
    return this.apiService
      .get("/trendz/user-permission?url=" + uniqueId).pipe(
      map(data => {
        return data.userPermissions;
      }));
  }

  // get user preferences

  getUserOrgPref(
    userId
  ): Observable<{ count: number; tnzUserOrgPreference: any }> {
    return this.apiService.get("/trendz/settings/" + userId).pipe(map(data => data));
  }

  // update user preferences
  updateOrgPref(tnzOrgPref, userId): Observable<any> {
    return this.apiService
      .put("/trendz/settings/" + userId, { tnzUserOrgPreference: tnzOrgPref }).pipe(
      map(data => data));
  }

  // create user preferences
  createOrgPref(tnzOrgPref, userId): Observable<any> {
    return this.apiService
      .post("/trendz/settings/" + userId, { tnzUserOrgPreference: tnzOrgPref }).pipe(
      map(data => data));
  }
}
