export class User {
    company: string;
    division: string;
    img: string;
    knownAs: string;
    message: string;
    token: string;
    userId: string;
    userName: string;
    email: string;
    BPMAuthentication: string;
    trendzBIAuthentication: string;
    resetToken: string;
    fleetManagementAuthentication: string;
    accommodationManagementAuthentication: string;
    personId : string;
}
