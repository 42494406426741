import { Directive, HostBinding, Input, OnChanges, HostListener } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { DomSanitizer } from '@angular/platform-browser';

import { ApiService } from './../../services/api.service';
import { FleetApiService } from './../../services/fleet-api.service';


@Directive({
	selector: '[pImage]',
})
export class ImageDirective implements OnChanges {

	@HostBinding('src') src;

	@Input() imageUrl: string;
	@Input() module: string;
	constructor(private http: HttpClient
		, private sanitizer: DomSanitizer
		, private apiService: ApiService
		, private flmApiService: FleetApiService) {

	}

	@HostListener('error') onError() {
		this.src = this.sanitizer.bypassSecurityTrustUrl('assets/images/avatars/user.jpg');
	}

	ngOnChanges() {
		if (this.module == 'FLM') {
			this.src = this.sanitizer.bypassSecurityTrustUrl('assets/images/vehicles/default.jpg');
			this.flmApiService.getImage(this.imageUrl)
				.subscribe(
					data => {
						if (data.text()) {
							this.src = this.sanitizer.bypassSecurityTrustUrl('data:image/jpeg;base64,' + data.text());
						}

					}
				);

		} else {
			if (this.imageUrl.startsWith('/images/persons/')) {
				this.src = this.sanitizer.bypassSecurityTrustUrl('/trendz-static-assets/profile-pic/' + this.imageUrl.replace('/images/persons/', '') + '.jpg');
			}
			else {
				this.src = this.sanitizer.bypassSecurityTrustUrl('assets/images/avatars/user.jpg');
				this.apiService.getImage(this.imageUrl)
					.subscribe(
						data => {
							if (data.text()) {
								// this.src = this.sanitizer.bypassSecurityTrustUrl('data:image/jpeg;base64,' + data.text());
							}

						}
					);
			}
		}
	}

	updateImage(data) {
		this.src = data;
	}

}
