import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-iframe',
  templateUrl: './iframe.component.html',
  styleUrls: ['./iframe.component.css']
})
export class IframeComponent {
  timeout;
  @ViewChild('iframe') iframe: ElementRef;
  @Input() iframeUrl = null;
  @Input() refresh = null;

  iframeUrlLoaded: Boolean = false;
  viewInitialized: Boolean = false;

  constructor() {
  }

  ngOnDestroy() {
    if (this.timeout) {
      clearInterval(this.timeout);
    }
  }

  ngOnChanges() {
    if (this.iframeUrl) {
      this.iframeUrlLoaded = true;
      if (this.iframe && this.viewInitialized && this.iframeUrlLoaded) {
        this.iframe.nativeElement.src = this.iframeUrl;
        if (this.refresh) {
          this.refreshIframe();
        }
      }
    }
  }

  ngAfterViewInit() {
    this.viewInitialized = true;
    if (this.iframe && this.viewInitialized && this.iframeUrlLoaded) {
      this.iframe.nativeElement.src = this.iframeUrl;
      if (this.refresh) {
        this.refreshIframe();
      }
    }
  }
  refreshIframe() {
    let cls = this;
    this.timeout = setInterval(function () {
      cls.iframe.nativeElement.src = cls.iframe.nativeElement.src
    }, cls.refresh * 1000);
  }

}
