import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { ApiService, FleetApiService, UtilityService } from 'app/shared/services';


@Component({
  selector: 'app-validate-uploaded',
  templateUrl: './validate-uploaded.component.html',
  styleUrls: ['./validate-uploaded.component.css']
})
export class ValidateUploadedComponent implements OnInit, OnDestroy {

  @Input() hasErrors;
  @Input() selectedPayPeriod;
  apiUrl;
  title;
  dataHeader;
  @Input() fullError = false;
  listAttrs;
  listAttrTitles;
  numeric = [];
  loading = false;
  apiClass = '';
  listItems;
  saveEnabled = false;
  errorFlag = 0;
  saveAllRecords = false;
  errorCount = 0;
  constructor(
    public dialogRef: MatDialogRef<ValidateUploadedComponent>
    , private dialog: MatDialog
    , private apiService: ApiService
    , private fleetApiService: FleetApiService
    , private utilityService: UtilityService
  ) { }

  ngOnInit() {
    this.saveEnabled = (!this.hasErrors);
  }
  ngOnDestroy() {
    delete this.apiUrl;
    delete this.dataHeader;
    delete this.listAttrs;
    delete this.listAttrTitles;
  }
  uploadRecord() {
    let json = {};
    let validList;
    if (!this.saveAllRecords)
      validList = this.listItems.filter(
        item => item.errorCount == '0');
    else validList = this.listItems;
    json[this.dataHeader] = validList;
    this.loading = true;
    if (this.apiClass == 'FLM') {
      this.fleetApiService.post(this.apiUrl, json).subscribe(
        response => {
          this.loading = false;
          if (response.status == 'S') {
            if (this.fullError == true)
              this.utilityService.showAlerts('No Records Imported');
            else
              this.utilityService.showAlerts(response.message);
            this.dialogRef.close(true);
          } else {
            this.utilityService.showAlerts('Failed to upload records ');
            this.dialogRef.close(false);
          }
        });
    }
    else {
      this.apiService.post(this.apiUrl, json).subscribe(
        response => {
          this.loading = false;
          if (response.status == 'S') {
            if (this.errorCount > 0) {
              this.utilityService.showAlerts((this.listItems.length - this.errorCount) + ' valid records saved');
              this.dialogRef.close(true);
            }
            else {
              if (this.fullError == true)
                this.utilityService.showAlerts('No Records Imported');
              else
                this.utilityService.showAlerts(response.message);
              this.dialogRef.close(true);
            }
          } else {
            this.utilityService.showAlerts('Failed to upload records ');
            this.dialogRef.close(false);
          }
        });
    }
  }

  totalErrorCount() {
    this.listItems.forEach(elm => {
      if (elm.dateErr || elm.timeErr) this.errorCount++;
    });
    if (this.errorCount == this.listItems.length) this.errorFlag = 1;
  }
}
