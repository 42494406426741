
import {catchError, map} from 'rxjs/operators';
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { BPMUtils } from 'app/shared/bpmutils/bpmutils.service';

import { UtilityService } from './../../services/utility.service';
import { DatePipe } from "@angular/common";
import { ApiService } from 'app/shared';
import { Mail } from 'app/mailbox/_models';

@Component({
	selector: 'document-action-form',
	templateUrl: './document-action-form.html',
	styleUrls: ['./document-action.component.scss']
})
export class DocumentActionFormComponent implements OnInit, OnDestroy {
	mail: Mail;
	isClaim = false;
	formGroup: FormGroup;
	private taskId;
	task;
	formFields;
	formDesc = '';
	formTitle = 'Document Action';
	formControls: FormControl[];
	commentFC: FormControl;
	docActionsLoading = false;
	mailLoaded = false;
	processInstanceId;
	appOwner;
	appObject;
	appObjectKey;
	@ViewChild('docActionCommonForm') docActionForm: ElementRef;
	constructor(
		private bpmUtils: BPMUtils,
		private dialogRef: MatDialogRef<DocumentActionFormComponent>,
		private fb: FormBuilder,
		private apiService: ApiService,
		private utilityService: UtilityService) {
	}

	ngOnInit() {
		this.formFields = [];
		this.formControls = [];
		if (this.isClaim) {
			this.formTitle = 'Claim this task';
		}
		else {
			this.fetchDocumentActions();;
		}
	}

	fetchDocumentActions() {
		this.docActionsLoading = true;
		if (this.task && this.task.taskId) {
			this.fetchMailDetails();
			this.formDesc = this.task.formDesc
			this.formTitle = this.task.formTitle
			this.taskId = this.task.taskId;
			this.formFields = this.task.formFields;
			this.formControls = [];
			this.commentFC = new FormControl();
			for (const field of this.formFields) {
				const fc = new FormControl();
				if (field.value) {
					if (field.type == 'date') {
						fc.setValue(this.utilityService.formatToCalDate(new Date(field.value)));
					} else if (field.type == 'enum') {
						if (field.enumValues) {
							if (field.enumValues.length == 1) {
								fc.setValue(field.enumValues[0].id);
							} else {
								field.enumValues.forEach(element => {
									if (element.id == field.value) {
										fc.setValue(field.value);
									}
								});
							}
						}
					}
					else {
						fc.setValue(field.value);
					}
				}
				if (field.required) {
					fc.setValidators(Validators.required);
				}
				if (!field.writable) { fc.disable(); }
				this.formControls[field.id] = fc;
			}
			this.docActionsLoading = false;
		}
	}

	ngOnDestroy() {

	}

	submitForm() {
		this.submitDocumentAction();
	}

	discardChanges() {
		this.dialogRef.close();
	}

	submitDocumentAction() {
		let properties = [];
		let valid = true;
		this.docActionsLoading = true;
		for (let field of this.formFields) {
			let fc = this.formControls[field.id];
			let value = fc.value;
			if (fc.valid) {
				if (field.type == 'date') {
					let datePipe = new DatePipe("en-US");
					value = datePipe.transform(value, 'MM/dd/yyyy');
				}
				properties.push({ "id": field.id, "value": value });
			} else {
				valid = false;
				fc.markAsTouched();
				break;
			}
		}
		if (valid) {
			let form = { "taskId": this.taskId, "properties": properties };
			this.bpmUtils.submitProcessForm(form).then((response) => {
				this.docActionsLoading = false;
				if (response.status == 200 || response.status == 404) {
					this.formFields = [];
					this.formControls = [];
					if (response.status == 200) {
						this.utilityService.showAlerts("Document action completed.");
						this.postComment();
						this.dialogRef.close(true);
					}
					else {
						this.utilityService.showAlerts("Task Not Found.");
					}
				}
			});
		}
		else {
			this.docActionsLoading = false;
			this.utilityService.showAlerts("Fill mandatory fields.");
		}
	}

	claimTask() {
		this.docActionsLoading = true;
		this.bpmUtils.getTaskIdFromInstance(this.processInstanceId)
			.subscribe((task) => {
				this.docActionsLoading = false;
				if (task && task.taskId) {
					this.bpmUtils.claimProcessTask(task.taskId).then((status) => {
						if (status == 200) {
							this.utilityService.showAlerts("Task claimed.");
							this.dialogRef.close('claimed')
						} else {
							this.utilityService.showAlerts("Task claim failed.");
						}
					});
				} else {
					this.utilityService.showAlerts('Failed to change assignee.');
				}
			}, err => {
				this.docActionsLoading = false;
			});

	}


	fetchMailDetails() {
		this.apiService.get('/mailbox/' + this.task.taskId)
			.subscribe(data => {
				if (data.status == 'S' && data.mail) {
					this.mail = new Mail(data.mail);
					this.mailLoaded = true;
				}
			}, err => {
				this.mailLoaded = true;
			});
	}

	postComment() {
		if (this.commentFC.value && this.mail.appOwner && this.mail.appObject && this.mail.appObjectKey) {
			const tnzComments = {
				commentType: "C",
				objectOwner: this.mail.appOwner,
				objectName: this.mail.appObject,
				objectPk: this.mail.appObjectKey,
				commentFullText: this.commentFC.value,
				destinationUrl: ""
			}
			this.apiService.post('/administration/comments', { tnzComments: tnzComments }).pipe(
				map(data => { return data }),
				catchError((err) => {
					return err;
				}),)
				.subscribe(data => {
					if (!data.status || data.status !== 'S')
						this.utilityService.showAlerts("Failed to post comment.");
				});
		}
	}
}
