import { throwError as observableThrowError, Observable, BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { UtilityService, UserService } from 'app/shared/services';

@Injectable()
export class SchedulerUtils {

    private static url: string;
    private static authentication: string = '';

    outputMimeTypes = {
        'PDF': 'application/pdf',
        'XLS': 'application/vnd.ms-excel',
        'JPG': 'image/jpeg',
        'PNG': 'image/png',
        'TXT': 'text/plain',
        'HTML': 'text/html',
        'CSV': 'text/csv',
        'DOC': 'application/msword',
        'XLSX': 'application/vnd.ms-excel'
    };

    onJobScheduled: BehaviorSubject<any> = new BehaviorSubject([]);

    constructor(
        private http: HttpClient,
        private utilityService: UtilityService,
        private userService: UserService,
    ) {
        SchedulerUtils.url = environment.bi_url;
        SchedulerUtils.authentication = utilityService.getLoggedInUser().token;
        this.userService.currentUser.subscribe(user => {
            SchedulerUtils.authentication = user.token;
        });
    }

    private setHeaders(authentication: string): HttpHeaders {
        const headersConfig = {
            'Content-Type': 'application/json; charset=UTF-8',
            'Accept': 'application/json',
        };
        headersConfig['Authorization'] = authentication;
        return new HttpHeaders(headersConfig);
    }

    private formatErrors(error: any) {
        return observableThrowError({status: error.status, data: error});
    }

    private get(path: string, params: HttpParams = new HttpParams(), token = Math.random()): Observable<any> {
        return this.http.get(`${SchedulerUtils.url}${path}`, {
            headers: this.setHeaders(SchedulerUtils.authentication),
            params: params,
            observe: 'response'
        })
            .catch(this.formatErrors)
            .map((res: HttpResponse<any>) => {
                let data: any = '';
                try {
                    data = res.body;
                    data.token = token;
                } catch (e) {
                    data = res;
                }
                return {status: res.status, data: data}
            });
    }

    private post(path: string, body: Object = {}, token = Math.random()): Observable<any> {
        return this.http.post(`${SchedulerUtils.url}${path}`, JSON.stringify(body), {
            headers: this.setHeaders(SchedulerUtils.authentication),
            observe: 'response'
        })
            .catch(this.formatErrors)
            .map((res: HttpResponse<any>) => {
                let data: any = '';
                try {
                    data = res.body;
                    data.token = token;
                } catch (e) {
                    data = res;
                }
                return {status: res.status, data: data}
            });
    }

    private put(path: string, body: Object = {}): Observable<any> {
        return this.http.put(`${SchedulerUtils.url}${path}`, JSON.stringify(body), {
            headers: this.setHeaders(SchedulerUtils.authentication),
            observe: 'response'
        })
            .catch(this.formatErrors)
            .map((res: HttpResponse<any>) => {
                let data: any = '';
                try {
                    data = res.body;
                } catch (e) {
                    data = res;
                }
                return {status: res.status, data: data}
            });
    }

    private delete(path: string): Observable<any> {
        return this.http.delete(`${SchedulerUtils.url}${path}`, {
            headers: this.setHeaders(SchedulerUtils.authentication),
            observe: 'response'
        })
            .catch(this.formatErrors)
            .map((res: HttpResponse<any>) => {
                let data: any = '';
                try {
                    data = res.body;
                } catch (e) {
                    data = res;
                }
                return {status: res.status, data: data}
            });
    }

    getUrl(): string {
        return SchedulerUtils.url;
    }

    getAuthentication(): string {
        return SchedulerUtils.authentication;
    }

    getAllPrograms(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.get('/programs')
                .subscribe(response => {
                    if (response.status == 200 || response.status == 206) {
                        resolve(response.data);
                    } else {
                        reject();
                    }
                }, err => {
                    reject();
                });
        });
    }

    getTaskflowReports(taskflowKey, module): Promise<any> {
        return new Promise((resolve, reject) => {
            this.get('/programs/reports/' + taskflowKey + '/' + module)
                .subscribe(response => {
                    if (response.status == 200 || response.status == 206) {
                        resolve(response.data);
                    } else {
                        reject();
                    }
                }, err => {
                    reject();
                });
        });
    }

    getTaskflowPrograms(taskflowKey, module): Promise<any> {
        return new Promise((resolve, reject) => {
            this.get('/programs/' + taskflowKey + '/' + module)
                .subscribe(response => {
                    if (response.status == 200 || response.status == 206) {
                        resolve(response.data);
                    } else {
                        reject();
                    }
                }, err => {
                    reject();
                });
        });
    }

    initProgram(pgmId, inputData?): Promise<any> {
        return new Promise((resolve, reject) => {
            if (inputData) {
                this.post('/programs/' + pgmId + '/init', inputData)
                    .subscribe(response => {
                        if (response.status == 200 || response.status == 206) {
                            resolve(response.data);
                        } else {
                            reject();
                        }
                    }, err => {
                        reject();
                    });
            } else {
                this.get('/programs/' + pgmId + '/init')
                    .subscribe(response => {
                        if (response.status == 200 || response.status == 206) {
                            resolve(response.data);
                        } else {
                            reject();
                        }
                    }, err => {
                        reject();
                    });
            }
        });
    }

    submitProgram(pgmId, job): Promise<any> {
        return new Promise((resolve, reject) => {
            this.post('/programs/' + pgmId + '/submit', job)
                .subscribe(response => {
                    if (response.status == 200 || response.status == 206) {
                        resolve(response.data);
                    } else {
                        reject();
                    }
                }, err => {
                    reject();
                });
        });
    }

    getAllJobs(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.get('/scheduler/jobs')
                .subscribe(response => {
                    if (response.status == 200 || response.status == 206) {
                        resolve(response.data);
                    } else {
                        reject();
                    }
                }, err => {
                    reject();
                });
        });
    }

    getJobParams(jobId): Promise<any> {
        return new Promise((resolve, reject) => {
            this.get('/scheduler/jobs/' + jobId + '/params')
                .subscribe(response => {
                    if (response.status == 200 || response.status == 206) {
                        resolve(response.data);
                    } else {
                        reject();
                    }
                }, err => {
                    reject();
                });
        });
    }

    getAllQueue(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.get('/scheduler/queue')
                .subscribe(response => {
                    if (response.status == 200 || response.status == 206) {
                        resolve(response.data);
                    } else {
                        reject();
                    }
                }, err => {
                    reject();
                });
        });
    }

    getQueueParams(queueId): Promise<any> {
        return new Promise((resolve, reject) => {
            this.get('/scheduler/queue/' + queueId + '/params')
                .subscribe(response => {
                    if (response.status == 200 || response.status == 206) {
                        resolve(response.data);
                    } else {
                        reject();
                    }
                }, err => {
                    reject();
                });
        });
    }

    reSchedule(jobId, job) {
        return new Promise((resolve, reject) => {
            this.post('/scheduler/queue/' + jobId + '/re-schedule', job)
                .subscribe(response => {
                    if (response.status == 200 || response.status == 206) {
                        resolve(response.data);
                    } else {
                        reject();
                    }
                }, err => {
                    reject();
                });
        });
    }

    suspendSchedule(jobId) {
        return new Promise((resolve, reject) => {
            this.put('/scheduler/queue/' + jobId + '/suspend')
                .subscribe(response => {
                    if (response.status == 200 || response.status == 206) {
                        resolve(response.data);
                    } else {
                        reject();
                    }
                }, err => {
                    reject();
                });
        });
    }

    resumeSchedule(jobId) {
        return new Promise((resolve, reject) => {
            this.put('/scheduler/queue/' + jobId + '/resume')
                .subscribe(response => {
                    if (response.status == 200 || response.status == 206) {
                        resolve(response.data);
                    } else {
                        reject();
                    }
                }, err => {
                    reject();
                });
        });
    }

    deleteSchedule(jobId) {
        return new Promise((resolve, reject) => {
            this.delete('/scheduler/queue/' + jobId + '/delete')
                .subscribe(response => {
                    if (response.status == 200 || response.status == 206) {
                        resolve(response.data);
                    } else {
                        reject();
                    }
                }, err => {
                    reject();
                });
        });
    }

    getJobLog(jobId): Promise<any> {
        return new Promise((resolve, reject) => {
            this.get('/scheduler/jobs/' + jobId + '/log-data')
                .subscribe(response => {
                    if (response.status == 200 || response.status == 206) {
                        resolve(response.data);
                    } else {
                        reject();
                    }
                }, err => {
                    reject();
                });
        });
    }

    getPreviewContent(key): any {
        return new Promise((resolve, reject) => {
            let content: any = {};
            content.type = 'url';
            content.data = this.getUrl() + '/scheduler/jobs/' + key + '/output-preview?token=' + this.getAuthentication();
            resolve(content);
            // this.get("/scheduler/jobs/" + key + "/output?token=" + this.getAuthentication()).subscribe(response => {
            //     if ((response.status == 200 || response.status == 206) && response.data) {
            //         resolve(response.data);
            //     } else {
            //         reject();
            //     }
            // }, err => {
            //     reject();
            // });
        });
    }

    getDownloadUrl(key): string {
        return this.getUrl() + '/scheduler/jobs/' + key + '/output?token=' + this.getAuthentication();
    }

    mailOutput(tnzJobInstncId, mailData): any {
        return new Promise((resolve, reject) => {
            this.post('/scheduler/jobs/' + tnzJobInstncId + '/mail-output', mailData)
                .subscribe(response => {
                    if (response.status == 200 || response.status == 206) {
                        resolve(response.data);
                    } else {
                        reject();
                    }
                }, err => {
                    reject();
                });
        });
    }

    getProgram(pgmId: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.get('/programs/' + pgmId)
                .subscribe(response => {
                    if (response.status == 200 || response.status == 206) {
                        resolve(response.data);
                    } else {
                        reject();
                    }
                }, err => {
                    reject();
                });
        });
    }

    getReportDetails(shortCode: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.get('/report/job/' + shortCode)
                .subscribe(response => {
                    if (response.status == 200 || response.status == 206) {
                        resolve(response.data);
                    } else {
                        reject();
                    }
                }, err => {
                    reject();
                });
        });
    }
}
