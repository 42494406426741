import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, AbstractControl, ValidatorFn, AsyncValidatorFn, ReactiveFormsModule  } from '@angular/forms';

import { PeoplezForm } from '../../'

@Component({
	selector: 'peoplez-form',
	templateUrl: './peoplez-form.component.html',
	styleUrls: ['./peoplez-form.component.css']
})
export class PeoplezFormComponent implements OnInit{

	@Input() formTitle: string;
	@Input() formAttributes: PeoplezForm[];
	@Output() editAction: EventEmitter<FormGroup>	= new EventEmitter();
	controls: {[key: string]: AbstractControl};
	formGroup: FormGroup = new FormGroup({});

	constructor(private fb: FormBuilder) { 

	}
	ngOnInit(){
		let dynamicFg: FormGroup = new FormGroup({});
		for (let p of this.formAttributes) {
			let control: FormControl = new FormControl('', Validators.required);
			dynamicFg.addControl(p.attribute, control);
		}
		//create detailsForm FormGroup using FormBuilder's short-hand syntax
		this.formGroup = this.fb.group({
			dynamicFg: dynamicFg
		});
		this.formGroup.disable();
	}

	editForm(){
		this.editAction.emit(this.formGroup);
	}

}
