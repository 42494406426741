
import {throwError as observableThrowError,  Observable ,  Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from "@angular/common/http";



import { JwtService } from './jwt.service';
import { UtilityService } from 'app/shared';


@Injectable()
export class ApiService {

	private emitChangeSource = new Subject<any>();
	changeEmitted$ = this.emitChangeSource.asObservable();

	constructor(
		private http: HttpClient,
		private jwtService: JwtService,
	) { }

	private setHeaders(): HttpHeaders {
		let headersConfig = {
			'Content-Type': 'application/json',
			'Accept': 'application/json',
		};
		if (this.jwtService.getToken()) {
			headersConfig['Authorization'] = `${this.jwtService.getToken()}`;
		}
		return new HttpHeaders(headersConfig);
	}
	private getMultipartHeaders(): HttpHeaders {
		let headersConfig = {
		};
		if (this.jwtService.getToken()) {
			headersConfig['Authorization'] = `${this.jwtService.getToken()}`;
		}
		return new HttpHeaders(headersConfig);
	}

	private formatErrors(error: any, path) {
		if (path != '/user/login') {
			this.emitChangeSource.next(error);
		}
		return observableThrowError(error);
	}

	 head(path: string, params: HttpParams = new HttpParams(), token = Math.random()): Observable<any> {
		return this.http.head(`${environment.api_url}${path}`, { headers: this.setHeaders(), params })
			.map((res: any) => {
			return res;
      })
	}

	get(path: string, params: HttpParams = new HttpParams(), token = Math.random()): Observable<any> {
		return this.http.get(`${environment.api_url}${path}`, { headers: this.setHeaders(), params })
			.catch((err) => { return this.formatErrors(err, path) })
			.map((res: any) => {
				let json = JSON.parse(JSON.stringify(res));
				json.token = token;
				return json;
			});
	}

	getHTTP(path: string, params: HttpParams = new HttpParams(), token = Math.random()): Observable<any> {
		return this.http.get(`${environment.api_url}${path}`, { headers: this.setHeaders(), params, observe: 'response' })
			// .catch((error) => {
			//   this.emitChangeSource.next(error);
			//   return Observable.throw({ status: error.status, data: error })
			// }
			.catch((err) => { return this.formatErrors(err, path) })
			.map((res: HttpResponse<any>) => {
				let data: any = '';
				try {
					data = res.body;
					data.token = token;
				} catch (e) {
					data = res;
				}
				return { status: res.status, data: data }
			});
	}

	getFence(path: string, params: HttpParams = new HttpParams()): Observable<any> {
		return this.http.get(`${path}`)
			.catch((err) => { return this.formatErrors(err, path) });
	}

	postFence(path: string, body: Object = {}): Observable<any> {
		return this.http.post(
			`${path}`,
			JSON.stringify(body)
		)
			.catch((err) => { return this.formatErrors(err, path) })
			.map((res: any) => res);
	}

	getImage(path: string, params: HttpParams = new HttpParams()): Observable<any> {
		const headersConfig = {
			'Content-Type': 'image/jpeg',
			'Accept': 'image/jpeg',
		};
		if (this.jwtService.getToken()) {
			headersConfig['Authorization'] = `${this.jwtService.getToken()}`;
		}
		return this.http.get(`${environment.api_url}${path}`, { headers: new HttpHeaders(headersConfig), params })
			.catch((err) => { return this.formatErrors(err, path) })
			.map((res: any) => res);
	}

	put(path: string, body: Object = {}): Observable<any> {
		return this.http.put(
			`${environment.api_url}${path}`,
			JSON.stringify(body),
			{ headers: this.setHeaders() }
		)
			.catch((err) => { return this.formatErrors(err, path) })
			.map((res: any) => res);
  }

  putWithParams(path: string, body: Object = {}, params: HttpParams = new HttpParams()): Observable<any> {
		return this.http.put(
			`${environment.api_url}${path}`,
			JSON.stringify(body),
			{ headers: this.setHeaders() , params},
		)
			.catch((err) => { return this.formatErrors(err, path) })
			.map((res: any) => res);
	}

	post(path: string, body: Object = {}): Observable<any> {
		return this.http.post(
			`${environment.api_url}${path}`,
			JSON.stringify(body),
			{ headers: this.setHeaders() }
		)
			.catch((err) => { return this.formatErrors(err, path) })
			.map((res: any) => res);
	}
	postMultipart(path: string, body): Observable<any> {
		return this.http.post(
			`${environment.api_url}${path}`, body, { headers: this.getMultipartHeaders() }
		)
			.catch((err) => { return this.formatErrors(err, path) })
			.map((res: any) => res);
	}

	delete(path): Observable<any> {
		return this.http.delete(
			`${environment.api_url}${path}`,
			{ headers: this.setHeaders() }
		)
			.catch((err) => { return this.formatErrors(err, path) })
			.map((res: any) => res);
	}
	getFile(path: string, params: HttpParams = new HttpParams(), token = Math.random()) {
		const link = document.createElement('a');
    	link.href = `${environment.api_url}${path}?Authorization=${this.jwtService.getToken()}`;
    	link.target = '_blank';
    	link.download = 'downloaded-file';
    	link.click();
		
	}

}
