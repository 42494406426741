import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { SmdDataTable } from 'app/shared/component/smd-datatable';
import { ApiService, FleetApiService, UtilityService } from 'app/shared/services';
import { MatDialogRef } from '@angular/material';
import { DatePipe } from '@angular/common';
import { environment } from 'environments/environment';
import { HttpParams } from '../../../../../node_modules/@angular/common/http';

@Component({
  selector: 'app-newlov',
  templateUrl: './newlov.component.html',
  styleUrls: ['./newlov.component.css']
})
export class NewlovComponent implements OnInit {

  assetEnv = environment.asset_url;
  selectMultiple: boolean = false;
  width = 'input-card no-padding popup-width';
  paginated: boolean = false;
  rowCount;
  application = 'HCM';
  countRows: boolean = false;
  title = "LOV";
  apiClass;
  apiMethod = 'GET';
  postBody: any = {};
  limit = 30;
  apiUrl;
  dataHeader;
  fields: any[];
  listAttrs;
  listAttrTitles;
  sortable: boolean = false;
  filterAttrs;
  numericAttrs = [];
  dateFieldAttrs = [];
  sortAttrs = [];
  searchParams: HttpParams;
  returnAttrs;
  isTitleNeeded = true;
  numeric: Array<boolean> = [];
  dateField: Array<boolean> = [];
  private dataSubs;
  records;
  recordsAll;
  searchEnabled = true;
  filterValue;
  model;
  searchDB = true;
  private init = false;
  toolTipAttrs = [];
  iconAttrs = [];
  icons = [];
  iconColorAttrs = [];
  flagAttrs = [];
  showTableFilter: boolean = true;
  showColumnFilter: boolean = false;

  @ViewChild(SmdDataTable) dataTable: SmdDataTable;
  @ViewChild('searchInput') searchInput: ElementRef;

  constructor(
    public dialogRef: MatDialogRef<NewlovComponent>,
    private apiService: ApiService,
    public fleetApiService: FleetApiService,
    private utilityService: UtilityService,
  ) { }

  ngOnInit() {
    if (!this.searchParams)
      this.searchParams = new HttpParams();
    if (!this.filterAttrs)
      this.filterAttrs = this.listAttrs;
    if (!this.listAttrTitles)
      this.listAttrTitles = this.listAttrs;
    if (!this.returnAttrs)
      this.returnAttrs = this.listAttrs;
    if (!this.apiClass)
      this.apiClass = this.apiService;
    if (!this.fields) {
      this.fields = [];
      if (this.listAttrs) {
        for (let i = 0; i < this.listAttrs.length; i++) {
          let field: any = {};
          field.key = this.listAttrs[i];
          field.title = this.listAttrTitles[i];
          field.toolTip = this.toolTipAttrs[i];
          field.iconImg = this.icons[i];
          field.iconColor = this.iconColorAttrs[i];
          field.filterable = this.filterAttrs.indexOf(field.key) > -1;
          field.sortable = this.numericAttrs.indexOf(field.key) > -1;
          field.dateField = this.dateFieldAttrs.indexOf(field.key) > -1;
          field.numeric = this.sortAttrs.indexOf(field.key) > -1;
          field.flag = this.flagAttrs.indexOf(field.key) > -1;
          field.icon = this.iconAttrs.indexOf(field.key) > -1;
          this.fields.push(field);
        }
      }
    }

  }

  ngOnDestroy() {
    if (this.dataSubs)
      this.dataSubs.unsubscribe();
    delete this.apiClass;
    delete this.apiUrl;
    delete this.dataHeader;
    delete this.listAttrs;
    delete this.listAttrTitles;
    delete this.filterAttrs;
    delete this.searchParams;
    delete this.returnAttrs;
    delete this.records;
  }

  rowSelected(event) {
    this.model = event.model;
    if (event.selected && !this.selectMultiple) {
      this.selectRecord();
    }
  }

  selectRecord() {
    if (this.selectMultiple) {
      this.dialogRef.close(this.dataTable.selectedModels());
    }
    else {
      if (this.model) {
        this.dialogRef.close(this.model);
      } else {
        this.utilityService.showAlerts('Selection failed');
      }
    }
  }

  toLower(upper) {
    return upper.toLowerCase();
  }
  convertToDate(date) {
    return this.formatToDisplayDate(date == '' ? null : new Date(date).toString());
  }

  formatToDisplayDate(date: string) {
    if (date) {
      let datePipe = new DatePipe("en-US");
      return datePipe.transform(date, 'dd-MMM-yyyy');
    }
    else return "";
  }

}
