import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'driveElementFilter'})
export class DriveElementFilterPipe implements PipeTransform
{
    transform(elements: any[], type: string, token:number): any
    {
        return elements.filter(element => {
            return element.baseType == type;
        });
    }
}
