
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { User } from 'app/shared/models';
import { ApiService, UserService, EventService } from 'app/shared/services';

@Component({
    selector: 'user-profile-menu',
    templateUrl: './user-profile-menu.component.html',
    styleUrls: ['./user-profile-menu.component.scss']
})
export class UserProfileMenuComponent implements OnInit, OnDestroy {
    currentUser: User;
    division = { label: '', value: '' };
    divisions: any[] = [];

    userSubs: Subscription;
    constructor(
        private router: Router,
        private apiService: ApiService,
        private userService: UserService,
        private eventService: EventService,
    ) {
    }

    ngOnInit() {
        this.userSubs = this.userService.currentUser.subscribe(
            (userData) => {
                this.currentUser = userData;
            }
        );
    }
    ngOnDestroy() {
        if (this.userSubs)
            this.userSubs.unsubscribe();
    }
    logout() {
        this.closeGadget();
        this.userService.purgeAuth();
        this.router.navigateByUrl('/login');
    }

    closeGadget() {
        this.eventService.showGadget.next(false);
    }

    mailBox(){
        this.closeGadget();
        this.router.navigateByUrl('/mailbox/inbox');
    }
    scheduler(){
        this.closeGadget();
        this.router.navigateByUrl('/scheduler/general');
    }
    trendzDrive(){
        this.closeGadget();
        this.router.navigateByUrl('/trendz-drive');
    }
    trendzUploads(){
        this.closeGadget();
        this.router.navigateByUrl('/trendz-upload');
    }
}