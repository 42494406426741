
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from "@angular/common/http";
import { MatDialog } from '@angular/material';
import { environment } from '../../../environments/environment';
import { LOVComponent } from './../component/lov-component/lov-component';
import { DocumentActionFormComponent } from './document-action-form/document-action-form';
import { ApiService} from 'app/shared/services/api.service';
import { JwtService} from 'app/shared/services/jwt.service';
import { UtilityService} from 'app/shared/services/utility.service';
import { UserService} from 'app/shared/services/user.service';

@Injectable()
export class BPMUtils {

    public static BPMUser: string;
    private static BPMAuthentication: string;

    constructor(
        private http: HttpClient,
        private jwtService: JwtService,
        private utilityService: UtilityService,
        private userService: UserService,
        private apiService: ApiService,
        private dialog: MatDialog
    ) {
        this.userService.currentUser.subscribe(
            (user) => {
                BPMUtils.BPMUser = user.userName;
                BPMUtils.BPMAuthentication = user.BPMAuthentication;
            });
    }

    private setHeaders(authentication: string): HttpHeaders {
        const headersConfig = {
            'Content-Type': 'application/json;charset=UTF-8',
            'Accept': 'application/json',
        };
        headersConfig['Authorization'] = 'Basic ' + authentication;
        return new HttpHeaders(headersConfig);
    }

    private formatErrors(error: any) {
        return observableThrowError({ status: error.status, data: error });
    }

    private get(path: string, url = environment.bpm_url, authentication = BPMUtils.BPMAuthentication): Observable<any> {
        return this.http.get(`${url}${path}`, { headers: this.setHeaders(authentication), observe: 'response' })
            .catch(this.formatErrors)
            .map((res: HttpResponse<any>) => {
                let data: any = '';
                try {
                    data = res.body;
                } catch (e) {
                    data = res;
                }
                return { status: res.status, data: data }
            });
    }


    private get2(bpmPath: string, bpmUrl = environment.bpm_url, authentication = BPMUtils.BPMAuthentication): Observable<any> {
        let params: HttpParams = new HttpParams();
        params = params.set('path', bpmPath);
        params = params.set('url', bpmUrl);
        params = params.set('authentication', BPMUtils.BPMAuthentication);
        const path = '/bpm/process'
        return this.http.get(`${environment.api_url}${path}`, { headers: this.setHeaders(authentication), params: params, observe: 'response'  })
            .catch(this.formatErrors)
            .map((res: HttpResponse<any>) => {
                let data: any = '';
                try {
                    data = res.body;
                } catch (e) {
                    data = res;
                }
                return { status: res.status, data: data }
            });
    }

    private post(path: string, body: Object = {}, url = environment.bpm_url, authentication = BPMUtils.BPMAuthentication): Observable<any> {
        return this.http.post(`${url}${path}`, JSON.stringify(body), { headers: this.setHeaders(authentication), observe: 'response'  })
            .catch(this.formatErrors)
            .map((res: HttpResponse<any>) => {
                let data: any = '';
                try {
                    data = res.body;
                } catch (e) {
                    data = res;
                }
                return { status: res.status, data: data }
            });
    }

    private put(path: string, body: Object = {}, url = environment.bpm_url, authentication = BPMUtils.BPMAuthentication): Observable<any> {
        return this.http.put(`${url}${path}`, JSON.stringify(body), { headers: this.setHeaders(authentication), observe: 'response'  })
            .catch(this.formatErrors)
            .map((res: HttpResponse<any>) => {
                let data: any = '';
                try {
                    data = res.body;
                } catch (e) {
                    data = res;
                }
                return { status: res.status, data: data }
            });
    }

    startProcessInstance(data, url = environment.bpm_url, authentication = BPMUtils.BPMAuthentication): Promise<string> {
        return new Promise((resolve, reject) => {
            this.post('runtime/process-instances', data)
                .subscribe(response => {
                    if (response.status == 201) {
                        resolve(response.data.id);
                    } else {
                        this.utilityService.showAlerts('Document action failed. BPM-FUN-101');
                        resolve(null);
                    }
                }, err => {
                    this.utilityService.showAlerts('Document action failed. BPM-FUN-101');
                    resolve(null);
                });
        });
    }

    getProcessTask(instanceId: string, assignee: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this.get('runtime/tasks/?assignee=' + assignee + '&processInstanceId=' + instanceId)
                .subscribe(response => {
                    if (response.status == 200) {
                        if (response.data.data[0]) {
                            const data: any = {};
                            data.taskId = response.data.data[0].id;
                            data.formTitle = response.data.data[0].name;
                            data.formDesc = response.data.data[0].description;
                            resolve(data);
                        }
                        resolve('CLAIM');
                    } else {
                        this.utilityService.showAlerts('Document action failed. BPM-FUN-102');
                        resolve(null);
                    }
                }, err => {
                    this.utilityService.showAlerts('Document action failed. BPM-FUN-102');
                    resolve(null);
                });
        });
    }

    getTaskFormFields(taskId: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this.get('form/form-data/?taskId=' + taskId)
                .subscribe(response => {
                    if (response.status == 200) {
                        resolve(response.data.formProperties);
                    } else {
                        this.utilityService.showAlerts('Document action failed. BPM-FUN-103');
                        resolve(null);
                    }
                }, err => {
                    this.utilityService.showAlerts('Document action failed. BPM-FUN-103');
                    resolve(null);
                });
        });
    }

    getBPMActions(instanceId: string, assignee: string, taskId: string = null): Promise<any> {
        return new Promise((resolve, reject) => {
            let task: any = {};
            task.taskId = taskId;
            this.getProcessTask(instanceId, assignee).then((response) => {
                if (response) {
                    if (response.taskId && (taskId == null || response.taskId == taskId)) {
                        this.getTaskFormFields(response.taskId).then((formFields) => {
                            task.taskId = response.taskId
                            task.action = 'SUBMIT';
                            task.formFields = formFields;
                            task.formTitle = response.formTitle;
                            task.formDesc = response.formDesc;
                            resolve(task);
                        });
                    } else if (response == 'CLAIM') {
                        task.action = 'CLAIM';
                        resolve(task);
                    }
                    else {
                        resolve(task);
                    }
                }
                else {
                    resolve(task);
                }
            }, (err) => {
                resolve(task)
            });
        });
    }

    submitProcessForm(form, BPMUrl = environment.bpm_url, authentication = BPMUtils.BPMAuthentication): Promise<any> {
        if (form.properties.length > 0) {
            return new Promise((resolve, reject) => {
                this.post('form/form-data/', form)
                    .subscribe(response => {
                        if (response.status == 200 || response.status == 204) {
                            resolve({ id: form.taskId, status: 200 });
                        }
                    }, err => {
                        this.utilityService.showAlerts('Document action failed. BPM-FUN-105');
                        resolve({ id: form.taskId, status: err.status });
                    });
            });
        } else {
            return this.completeProcessTask(form.taskId);
        }
    }

    private taskActions(taskId: string, data: any): Promise<number> {
        return new Promise((resolve, reject) => {
            this.post('runtime/tasks/' + taskId, data)
                .subscribe(response => {
                    if (response.status == 200 || response.status == 204) {
                        resolve(200);
                    }
                }, err => {
                    if (err.status == 409) {
                        this.utilityService.showAlerts('This task is already claimed by someone else');
                        resolve(404);
                    } else {
                        this.utilityService.showAlerts('Document action failed. BPM-FUN-107');
                        resolve(500);
                    }
                    resolve(err.status);
                });
        });
    }

    private updateProcessTask(taskId: string, data: any): Promise<number> {
        return new Promise((resolve, reject) => {
            this.put('runtime/tasks/' + taskId, data)
                .subscribe(response => {
                    if (response.status == 200) {
                        resolve(200);
                    }
                }, err => {
                    if (err.status == 409) {
                        this.utilityService.showAlerts('This task is already completed/claimed by someone else');
                        resolve(404);
                    } else if (err.status == 404) {
                        this.utilityService.showAlerts('Document action failed. Bad request');
                        resolve(500);
                    } else {
                        this.utilityService.showAlerts('Document action failed. BPM-FUN-107');
                        resolve(500);
                    }
                    resolve(err.status);
                });
        });
    }
    isTaskClaimAllowed(instanceId) {
        return this.apiService.get('/bpm/' + instanceId + '/security/claim').pipe(map(res => res))
    }
    completeProcessTask(taskId: string): Promise<number> {
        return this.taskActions(taskId, { action: 'complete' });
    }

    claimProcessTask(taskId: string): Promise<number> {
        return this.taskActions(taskId, { action: 'claim', assignee: BPMUtils.BPMUser });
    }

    resolveProcessTask(taskId: string): Promise<number> {
        return this.taskActions(taskId, { action: 'resolve' });
    }

    assignTask(taskId, userName): Promise<number> {
        return this.updateProcessTask(taskId, { assignee: userName })
    }

    changeAssignee(instanceId, taskId): Promise<boolean> {
        return new Promise((resolve, reject) => {
            if (instanceId && taskId) {
                const dialogRef = this.dialog.open(LOVComponent);
                dialogRef.componentInstance.searchDB = false;
                dialogRef.componentInstance.title = 'Change Assignee';
                dialogRef.componentInstance.apiUrl = '/bpm/' + instanceId + '/assignees';
                dialogRef.componentInstance.dataHeader = 'users';
                dialogRef.componentInstance.listAttrs = ['userName', 'fullName', 'knownAs'];
                dialogRef.componentInstance.listAttrTitles = ['Username', 'Full Name', 'Known As'];

                dialogRef.afterClosed().subscribe(result => {
                    if (result && result.userName) {
                        this.assignTask(taskId, result.userName).then((status) => {
                            if (status == 200) {
                                this.utilityService.showAlerts('This task is now assigned to ' + result.userName + '.');
                                resolve(true)
                            } else {
                                this.utilityService.showAlerts('Task assignment failed');
                                resolve(false);
                            }
                        });
                    }
                });
            } else {
                this.utilityService.showAlerts('Invalid request');
                resolve(false);
            }
        });
    }

    getTaskIdFromInstance(instanceId) {
        return this.apiService.get('/bpm/' + instanceId + '/task').pipe(map(data => data));
    }

    getTaskDetatilsFromInstance(instanceId) {
        return this.apiService.get('/bpm/' + instanceId + '/task-details').pipe(map(data => data.task));
    }
}
