import { Directive, Output, EventEmitter, HostListener } from '@angular/core';

@Directive({
    selector: '[dragDropElement]'
})
export class DragDropDirective {


    @Output() onFileDropped = new EventEmitter<any>();

    //Dragover listener
    @HostListener('dragover', ['$event']) onDragOver(evt) {
        evt.preventDefault();
        evt.stopPropagation();
        evt.srcElement.classList.add('drop-active');
    }

    //Dragleave listener
    @HostListener('dragleave', ['$event']) public onDragLeave(evt) {
        evt.preventDefault();
        evt.stopPropagation();
        evt.srcElement.classList.remove('drop-active');
    }

    //Drop listener
    @HostListener('drop', ['$event']) public ondrop(evt) {
        evt.preventDefault();
        evt.stopPropagation();
        evt.srcElement.classList.remove('drop-active');
        let files = evt.dataTransfer.files;
        if (files.length > 0) {
            this.onFileDropped.emit(files)
        }
    }

}