export class Folder {
	handle: string;
	title: string;
	icon: string;
	count: number;
	searchCount: number;
	color: string;

	constructor(folder) {
		this.handle = folder.handle;
		this.title = folder.title;
		this.icon = folder.icon;
		this.count = folder.count;
		this.searchCount = folder.searchCount;
		this.color = folder.color;
	}
}

export class Mail {
	id: string;
	processInstanceId: string;
	executionId: string;
	from: string;
	fromUser: string;
	fromId: string;
	to: string;
	toUser: string;
	toId: string;
	subject: string;
	desc: string;
	message: string;
	time: string;
	dueDate: any;
	avatarText: string;
	handles: string[];
	index: number;
	timestamp: number;
	appOwner: string;
	appObject: string;
	appObjectKey: string;
	appTFShortCode: string;
	appTFName: string;
	appTFUrl: string;
	trendzVersion: string;
	isVersioned: string;
	versionNo: string;
	cmisRoot: string;
	drivePath: string;
	taskStatus: string;
	division:string;

	constructor(mail) {
		this.id = mail.id;
		this.processInstanceId = mail.processInstanceId;
		this.executionId = mail.executionId;
		this.from = mail.from;
		this.fromUser = mail.fromUser;
		this.fromId = mail.fromId;
		this.to = mail.to;
		this.toUser = mail.toUser;
		this.toId = mail.toId;
		this.subject = mail.subject;
		this.desc = mail.desc;
		this.message = mail.message;
		this.time = mail.time;
		this.dueDate = mail.dueDate;
		this.avatarText = mail.avatarText;
		this.handles = mail.handles;
		if (this.handles) {
			if (this.handles.indexOf("outbox") > -1) {
				this.handles = ["all", "outbox"]
			} else {
				this.handles.unshift("all");
			}
		}
		this.index = mail.index;
		this.timestamp = mail.timestamp;
		this.appOwner = mail.appOwner;
		this.appObject = mail.appObject;
		this.appObjectKey = mail.appObjectKey;
		this.appTFShortCode = mail.appTFShortCode;
		this.appTFName = mail.appTFName;
		this.appTFUrl = mail.appTFUrl;
		this.trendzVersion = mail.trendzVersion;
		this.isVersioned = mail.isVersioned;
		this.versionNo = mail.versionNo;
		this.cmisRoot = mail.cmisRoot;
		this.drivePath = mail.drivePath;
		this.taskStatus = mail.taskStatus;
		this.division = mail.division;
	}
}