
import {map} from 'rxjs/operators';
import { Component, OnChanges, Input } from '@angular/core';

import { DomSanitizer } from '@angular/platform-browser';
import { FleetApiService } from 'app/shared/services/fleet-api.service';
import { UtilityService } from 'app/shared/services/utility.service';

@Component({
  selector: 'common-vehicle-details',
  templateUrl: './vehicle-details.component.html',
  styleUrls: ['./vehicle-details.component.scss']
})
export class VehicleDetailsComponent implements OnChanges {


  @Input() vehicleId;
  showDom = false;
  vehicleDetails: any = {};;

  constructor(private apiService: FleetApiService,

    private sanitizer: DomSanitizer,
    public utilityService: UtilityService) { }

  ngOnChanges() {
    if (this.vehicleId) {
      this.apiService.get('/vehicle/' + this.vehicleId).pipe(
        map(data => data.flmVehicle))
        .subscribe(data => {
          if (data) {
            this.vehicleDetails = data;
            this.showDom = true;

            if (data.model.value) {
              if (data.trimCode && data.trimCode.value && data.trimCode.value != "") {
                var trimCodeId = data.trimCode.value
              } else {
                var trimCodeId = data.model.value;
              }
              this.apiService.get("/vehicle/models/" + trimCodeId)
                .subscribe(data => {
                  if (data.flmVehicleModel && typeof data.flmVehicleModel.modelId !== 'undefined') {
                    if (data.flmVehicleModel.colour) {
                      this.vehicleDetails.colour = data.flmVehicleModel.colour;
                    }
                    if (data.flmVehicleModel.fuelType) {
                      this.vehicleDetails.fuelType = data.flmVehicleModel.fuelType;
                    }

                  }
                });

            }

          }
        });
    }
  }

}
