import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {HttpParams} from "@angular/common/http";
import { MatDialogRef, MatDialog } from '@angular/material';

import { UtilityService } from '../../services/utility.service';
import { ApiService } from './../../services/api.service';
import { SmdDataTable } from './../smd-datatable/datatable.component';
import { DatePipe } from '@angular/common';
import { environment } from 'environments/environment';
import { FleetApiService } from 'app/shared/services/fleet-api.service';
import { ConfirmPopupComponent } from 'app/shared/component/confirm-popup';

@Component({
	selector: 'lov-component-v2',
	templateUrl: './lov-component-v2.html',
	styleUrls: ['./lov-component-v2.scss']

})
export class LOVComponentV2 implements OnInit, OnDestroy {
	selectedRows: any;
	primaryKey;
	assetEnv = environment.asset_url;
	selectMultiple: boolean = false;
	width = 'input-card no-padding popup-width';
	paginated: boolean = false;
	rowCount;
	application = 'HCM';
	countRows: boolean = false;
	title = "LOV";
	apiClass;
	apiMethod = 'GET';
	postBody: any = {};
	limit = 30;
	apiUrl;
	dataHeader;
	fields: any[];
	listAttrs;
	listAttrTitles;
	sortable: boolean = false;
	filterAttrs;
	numericAttrs = [];
	dateFieldAttrs = [];
	timeFieldAttrs = [];
	sortAttrs = [];
	toggleAttrs = [];
	searchParams: HttpParams;
	returnAttrs;
	isTitleNeeded = true;
	numeric: Array<boolean> = [];
	dateField: Array<boolean> = [];
	private dataSubs;
	records;
	recordsAll;
	searchEnabled = true;
	filterValue;
	model;
	searchDB = true;
	private init = false;
	toolTipAttrs = [];
	iconAttrs = [];
	icons = [];
	iconColorAttrs = [];
	flagAttrs = [];
	showTableFilter: boolean = true;
	showColumnFilter: boolean = false;
	allRowChecked: any;
	callback: any;
	params: any[];
	paramCount: number;
	setListAsLastParam: boolean = false;
	valid: boolean = true;
	checkValid: boolean = false;
	newList: any = [];
	selectedRangeValue: number = 10;
	mainList: any = [];
	confirmDialogTitle: string = '';
	confirmDialogMessage: string = '';
	existingattrs = [];
	uniqueKey;

	@ViewChild(SmdDataTable) dataTable: SmdDataTable;
	@ViewChild('searchInput') searchInput: ElementRef;
	// @ViewChild('sideTable') sideListTable: SmdDataTable;


	constructor(
		public dialogRef: MatDialogRef<LOVComponentV2>,
		private apiService: ApiService,
		public fleetApiService: FleetApiService,
		private utilityService: UtilityService,
		private dialog: MatDialog,

	) { }

	ngOnInit() {
		if (this.setListAsLastParam) {
			this.paramCount = this.params.length + 1;
		} else {
			this.paramCount = this.params.length;
		}

		if (!this.searchParams)
			this.searchParams = new HttpParams();
		if (!this.filterAttrs)
			this.filterAttrs = this.listAttrs;
		if (!this.listAttrTitles)
			this.listAttrTitles = this.listAttrs;
		if (!this.returnAttrs)
			this.returnAttrs = this.listAttrs;
		if (!this.apiClass)
			this.apiClass = this.apiService;
		if (!this.fields) {
			this.fields = [];
			if (this.listAttrs) {
				for (let i = 0; i < this.listAttrs.length; i++) {
					let field: any = {};
					field.key = this.listAttrs[i];
					field.title = this.listAttrTitles[i];
					field.toolTip = this.toolTipAttrs[i];
					field.iconImg = this.icons[i];
					field.iconColor = this.iconColorAttrs[i];
					field.filterable = this.filterAttrs.indexOf(field.key) > -1;
					field.sortable = this.numericAttrs.indexOf(field.key) > -1;
					field.dateField = this.dateFieldAttrs.indexOf(field.key) > -1;
					field.timeField = this.timeFieldAttrs.indexOf(field.key) > -1;
					field.numeric = this.sortAttrs.indexOf(field.key) > -1;
					field.flag = this.flagAttrs.indexOf(field.key) > -1;
					field.icon = this.iconAttrs.indexOf(field.key) > -1;
					field.toggle = this.toggleAttrs.indexOf(field.key) > -1;
					this.fields.push(field);
				}
			}
		}
	}

	ngOnDestroy() {
		if (this.dataSubs)
			this.dataSubs.unsubscribe();
		delete this.apiClass;
		delete this.apiUrl;
		delete this.dataHeader;
		delete this.listAttrs;
		delete this.listAttrTitles;
		delete this.filterAttrs;
		delete this.searchParams;
		delete this.returnAttrs;
		delete this.records;
		delete this.toggleAttrs;
		delete this.params;
		delete this.allRowChecked;
	}

	rowSelected(event) {
		this.model = event.model;
		if (event.selected && !this.selectMultiple) {
			this.selectRecord();
		}
	}

	checkedRows(event) {
		if (event.checkedArr.checkedList) {
			this.selectedRows = event.checkedArr.checkedList;
		}
	}

	allCheckedRows(event) {
		if (this.params) {
			if (this.setListAsLastParam) {
				this.params[this.paramCount - 1] = event;
			}
			switch (this.paramCount) {
				case 0:
					this.valid = this.allRowChecked();

					break;
				case 1:
					this.valid = this.allRowChecked(this.params[0]);

					break;
				case 2:
					this.valid = this.allRowChecked(this.params[0], this.params[1]);

					break;
				case 3:
					this.valid = this.allRowChecked(this.params[0], this.params[1], this.params[2]);

					break;
				case 4:
					this.valid = this.allRowChecked(this.params[0], this.params[1], this.params[2], this.params[3]);

					break;
				case 5:
					this.valid = this.allRowChecked(this.params[0], this.params[1], this.params[2], this.params[3], this.params[4]);

					break;
				case 6:
					this.valid = this.allRowChecked(this.params[0], this.params[1], this.params[2], this.params[3], this.params[4], this.params[5]);

					break;
				case 7:
					this.valid = this.allRowChecked(this.params[0], this.params[1], this.params[2], this.params[3], this.params[4], this.params[5], this.params[6]);

					break;
			}
		}
	}

	selectRecord() {
		if (this.selectMultiple) {



			if (this.primaryKey && this.primaryKey.length > 0 && this.selectedRows)
				this.dialogRef.close(this.selectedRows);
			else
				this.dialogRef.close(this.dataTable.selectedModels());
		}
		else {
			if (this.model) {
				this.dialogRef.close(this.model);
			} else {
				this.utilityService.showAlerts('Selection failed');
			}
		}
	}

	select() {
		if (this.checkValid) {
			if (this.valid) {

				if (this.primaryKey && this.primaryKey.length > 0 && this.selectedRows)
					this.dialogRef.close(this.selectedRows);
				else
					this.dialogRef.close(this.dataTable.selectedModels());
			} else {
				const dialogRef = this.dialog.open(ConfirmPopupComponent);
				dialogRef.componentInstance.dialogTitle = this.confirmDialogTitle;
				dialogRef.componentInstance.message = this.confirmDialogMessage;
				dialogRef.componentInstance.confirmText = 'CONFIRM';
				dialogRef.afterClosed().subscribe(result => {
					if (result) {
						this.dialogRef.close(this.dataTable.selectedModels());
					}
				});
				// this.utilityService.showAlerts('Selection failed');
			}
		} else {
			this.selectRecord();
		}
	}

	toLower(upper) {
		return upper.toLowerCase();
	}
	convertToDate(date) {
		return this.formatToDisplayDate(!date || date == '' ? null : new Date(date).toString());
	}

	convertToDateTime(date) {
		return this.formatToDisplayTime(!date || date == '' ? null : new Date(date).toString());
	}

	formatToDisplayDate(date: string) {
		if (date) {
			let datePipe = new DatePipe("en-US");
			return datePipe.transform(date, 'dd-MMM-yyyy');
		}
		else return "";
	}
	formatToDisplayTime(date: string) {
		if (date) {
			let datePipe = new DatePipe("en-US");
			return datePipe.transform(date, 'dd-MMM-yyyy HH:mm');
		}
		else return "";
	}

	_onDataChange(dataChange: any) {


		if (this.existingattrs.length > 0 && this.uniqueKey) {

			let apiData = [];
			apiData = dataChange.data[this.dataHeader];
			for (let idx = 0; idx < apiData.length; idx++) {
				let index = this.existingattrs.indexOf(apiData[idx][this.uniqueKey]);
				if (index >= 0) {
					apiData.splice(idx, 1);
					idx--;
				}
			}
			this.dataTable.refresh(apiData);
		}

	}

	// Side table
	// selectedRange(selectedRange) {
	// 	this.selectedRangeValue = selectedRange;
	// }

	// checkedPksNew(e) {
	// 	this.newList = e.checkedArr.checkedList;
	// 	this.mainList = e.checkedArr.checkedPks;
	// 	this.sideListTable.refresh(this.newList);
	// }

}

