import { ErrorHandler, Injectable, Injector } from '@angular/core';
@Injectable()
export class TrendzErrorHandler extends ErrorHandler {
    constructor(private injector: Injector) {
        super();
    }

    handleError(error) {
        // super.handleError(error);
        // const loggingService = this.injector.get(LoggingService);
        if (error) {
            const message = error.message ? error.message : error.toString();
            // this.utilityService.showAlerts('Err')
            throw error;
        }
        // log on the server
        // loggingService.log({ message });
    }

}