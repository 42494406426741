import { Directive, HostListener, Input, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Subscription } from 'rxjs';

import { UtilityService } from 'app/shared/services';
import { TrendzUsersService } from 'app/administration/trendz/trendz-users/_services';
import { TrendzDriveModalComponent } from '../drive-modal/drive-modal.component';

@Directive({
	selector: '[trendzDriveBtn]',
})
export class DriveButtonDirective implements OnDestroy {
	@Input() root: string;
	@Input() basePath: string;
	@Input() appKey = "";
	@Input() privileges;
	@Input() extraPaths = "";

	previlegeSubs: Subscription;

	constructor(
		private dialog: MatDialog,
		private userService: TrendzUsersService,
		private utilityService: UtilityService
	) { }

	ngOnDestroy(): void {
		if (this.previlegeSubs)
			this.previlegeSubs.unsubscribe();
	}

	@HostListener('click') onClick() {
		if (this.privileges) {
			this.openDrive();
		}
		else if (this.appKey) {
			this.userService.checkPrevilage(this.appKey)
				.subscribe(data => {
					this.privileges = data;
					if (this.privileges.view == 4) {
						this.openDrive();
					}
					else {
						this.utilityService.showAlerts('You don\'t have permission to access drive');
					}
				},
				err => {
					this.privileges = { view: 0, create: 0, edit: 0, delete: 0 };
				});
		}
		else {
			this.utilityService.showAlerts('You don\'t have permission to access drive');
		}
	}

	openDrive() {
		let dialogRef = this.dialog.open(TrendzDriveModalComponent,{autoFocus:false});
		dialogRef.componentInstance.root = this.root;
		dialogRef.componentInstance.basePath = this.basePath;
		dialogRef.componentInstance.privileges = this.privileges;
		dialogRef.componentInstance.extraPaths = this.extraPaths;
	}
}
