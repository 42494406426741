import { Injectable } from '@angular/core';
import { ApiService, JwtService } from 'app/shared/services';
import { HttpClient, HttpRequest, HttpEventType, HttpResponse, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';


@Injectable()
export class TrendzDriveService {

	currentComponentInstance;

	constructor(
		private http: HttpClient,
		private jwtService: JwtService,
		private apiService: ApiService
	) { }

	private setHeaders(): HttpHeaders {
		let headersConfig = {
			'Content-Type': 'application/json',
			'Accept': 'application/json',
		};
		if (this.jwtService.getToken()) {
			headersConfig['Authorization'] = `${this.jwtService.getToken()}`;
		}
		return new HttpHeaders(headersConfig);
	}

	private getPath(path) {
		return path ? btoa(atob(path).replace(/\/\//g, '/')) : "";
	}

	initDrive(key): Promise<any> {
		return new Promise((resolve, reject) => {
			if (key) {
				return this.apiService.post('/trendz-drive/init/' + this.getPath(key)).subscribe(data => {
					resolve(data);
				}, err => {
					reject(err.message);
				});
			}
			else {
				reject("Invalid request");
			}
		});
	}

	renameObject(key, newName): Promise<any> {
		return new Promise((resolve, reject) => {
			if (key && newName) {
				return this.apiService.put('/trendz-drive/node/rename', { key: this.getPath(key), name: newName }).subscribe(data => {
					resolve(data);
				}, err => {
					reject(err.message);
				});
			}
			else {
				reject("Invalid request");
			}
		});
	}

	removeObject(key): Promise<any> {
		return new Promise((resolve, reject) => {
			if (key) {
				return this.apiService.delete('/trendz-drive/node/' + this.getPath(key)).subscribe(data => {
					resolve(data);
				}, err => {
					reject(err.message);
				});
			}
			else {
				reject("Invalid request");
			}
		});
	}

	getFolder(key): Promise<any> {
		return new Promise((resolve, reject) => {
			if (key) {
				return this.apiService.get('/trendz-drive/folders/' + this.getPath(key)).subscribe(data => {
					if (data.status && data.status == 'S')
						resolve(data);
					else
						reject(data.message);
				}, err => {
					reject(err.message);
				});
			}
			else {
				reject("Invalid request");
			}
		});
	}

	createFolder(title, parentKey): Promise<any> {
		return new Promise((resolve, reject) => {
			if (title && parentKey) {
				return this.apiService.post('/trendz-drive/folders', { name: title, key: this.getPath(parentKey) }).subscribe(data => {
					resolve(data);
				}, err => {
					reject(err.message);
				});
			}
			else {
				reject("Invalid request");
			}
		});
	}

	getDocument(key): Promise<any> {
		return new Promise((resolve, reject) => {
			if (key) {
				return this.apiService.get('/trendz-drive/docs/' + this.getPath(key)).subscribe(data => {
					resolve(data);
				}, err => {
					reject(err.message);
				});
			}
			else {
				reject("Invalid request");
			}
		});
	}

	getPreviewContent(key): any {
		return new Promise((resolve, reject) => {
			this.getDocument(this.getPath(key)).then(data => {
				let content: any = {};
				content.type = 'base64';
				content.data = data.document.content;
				resolve(content);
			}, reject);
		});
	}

	createDocument(key, document, progressCallback): Promise<any> {
		return new Promise((resolve, reject) => {
			if (key && document.fileName) {
				const req = new HttpRequest('POST', environment.api_url + '/trendz-drive/docs', { key: this.getPath(key), document: document }, {
					headers: this.setHeaders(),
					reportProgress: true,
					responseType: "json"
				});
				this.http.request(req).subscribe(event => {
					if (event.type === HttpEventType.UploadProgress) {
						const progress = Math.round(100 * event.loaded / event.total);
						progressCallback(progress);
					} else if (event instanceof HttpResponse) {
						resolve(event.body);
					}
				}, err => {
					reject(err.message);
				});
				
				// return this.apiService.post('/trendz-drive/docs', { key: key, document: document }).subscribe(data => {
				// 	resolve(data);
				// }, err => {
				// 	reject(err.message);
				// });
			}
			else {
				reject("Invalid request");
			}
		});
	}

	copyPaste(objects, from, to, mode = "copy"): Promise<any> {
		return new Promise((resolve, reject) => {
			if (objects.length > 0 && from && to) {
				return this.apiService.put('/trendz-drive/node/copy-paste', { objects: objects, from: this.getPath(from), to: this.getPath(to), mode: mode }).subscribe(data => {
					resolve(data);
				}, err => {
					reject(err.message);
				});
			}
			else {
				reject("Invalid request");
			}
		});
	}
	
	b64BlobConversion(b64Data, contentType) {
		contentType = contentType || '';
		var sliceSize = 512;
		b64Data = b64Data.replace(/^[^,]+,/, '');
		b64Data = b64Data.replace(/\s/g, '');
		var byteCharacters = window.atob(b64Data);
		var byteArrays = [];

		for (var offset = 0; offset < byteCharacters.length; offset = offset + sliceSize) {
			var slice = byteCharacters.slice(offset, offset + sliceSize);

			var byteNumbers = new Array(slice.length);
			for (var i = 0; i < slice.length; i++) {
				byteNumbers[i] = slice.charCodeAt(i);
			}

			var byteArray = new Uint8Array(byteNumbers);

			byteArrays.push(byteArray);
		}

		var blob = new Blob(byteArrays, { type: contentType });
		return blob;
	}

}
