import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
    MatIconModule,
	MatDialogModule,
	MatMenuModule,
	MatButtonModule,
	MatTooltipModule,
	MatRippleModule,
	MatToolbarModule,
	MatInputModule,
	MatCardModule
} from '@angular/material';

import { AuthGuard, UtilityService } from 'app/shared/services';
import { TrendzDriveInputModalComponent, TrendzDriveModalComponent } from './';
import { TrendzDriveService } from './_services';
import { TrendzDriveComponent } from './trendz-drive.component';
import { DriveElementFilterPipe } from 'app/trendzdrive/utils';
import { RadialProgressComponent } from 'app/shared/component/progress';
import { TrendzPipesModule } from 'app/shared/pipes/pipes.module';
import { ThumbnailDirective } from 'app/trendzdrive/utils/thumbnail.directive';
import { TrendzDriveUserHomeComponent } from 'app/trendzdrive/drive-user-home/drive-user-home.component';
import { TrendzUsersService } from 'app/administration/trendz/trendz-users/_services';
import { DragDropDirective } from 'app/shared/directives/drag-drop.directive';

const MATERIAL_MOD = [
	MatIconModule,
	MatDialogModule,
	MatMenuModule,
	MatButtonModule,
	MatTooltipModule,
    MatToolbarModule,
	MatRippleModule,
	MatInputModule,
	MatCardModule
];
const mailboxRouting: ModuleWithProviders = RouterModule.forChild([
	{
		path: 'home',
		canActivate: [AuthGuard],
		component: TrendzDriveUserHomeComponent
	}
]);
@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		MATERIAL_MOD,
		ReactiveFormsModule,
		HttpClientModule, 
		TrendzPipesModule
	],
	declarations: [
		TrendzDriveModalComponent,
		TrendzDriveComponent,
		TrendzDriveInputModalComponent,
		DriveElementFilterPipe,
		RadialProgressComponent,
		ThumbnailDirective,
		DragDropDirective,
		TrendzDriveUserHomeComponent
	],
	entryComponents: [
		TrendzDriveModalComponent,
		TrendzDriveInputModalComponent,
	],
	providers: [
		TrendzDriveService,
		TrendzUsersService,
		UtilityService
	],
    exports: [
		TrendzDriveModalComponent,
		TrendzDriveComponent,
		TrendzDriveInputModalComponent,
		DriveElementFilterPipe,
		RadialProgressComponent,
		ThumbnailDirective,
		TrendzDriveUserHomeComponent
    ]
})
export class TrendzDriveModule { }
