import { MatDialogRef } from '@angular/material';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ImageCropperComponent, CropperSettings } from 'ng2-img-cropper';


@Component({
  selector: 'app-crop-image',
  templateUrl: './crop-image.component.html',
  styleUrls: ['./crop-image.component.css']
})
export class CropImageComponent implements OnInit {

  data: any;
  imageUploaded = false;
  cropperSettings: CropperSettings;
  @ViewChild('cropper', undefined) cropper: ImageCropperComponent;
  constructor(private dialogRef: MatDialogRef<CropImageComponent>) {
    this.cropperSettings = new CropperSettings();
    // this.cropperSettings.width = 400;
    // this.cropperSettings.height = 300;
    this.cropperSettings.croppedWidth = 256;
    this.cropperSettings.croppedHeight = 256;
    // this.cropperSettings.canvasWidth = 400;
    // this.cropperSettings.canvasHeight = 300;
    this.cropperSettings.dynamicSizing = true;
    this.cropperSettings.cropperClass = 'ui-cropper';
    this.data = {};
    this.cropperSettings.noFileInput = true;
  }

  ngOnInit() {
  }

  fileChangeListener($event) {
    const image: any = new Image();
    const file: File = $event.target.files[0];
    if (file) {
      const myReader: FileReader = new FileReader();
      const that = this;
      myReader.onloadend = function (loadEvent: any) {
        image.src = loadEvent.target.result;
        that.cropper.setImage(image);
      };
      myReader.readAsDataURL(file);
      this.imageUploaded = true;
    }
  }
  selectImage() {
    if (this.data.image) {
      this.dialogRef.close(this.data.image);
    }
  }
}
