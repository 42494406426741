export const environment = {
  production: true,
  api_url: 'http://localhost:8090/hcm-web',
  flm_url: 'http://dev.v4.trendzerp.com/flm-web',
  bpm_url: 'http://dev.v4.trendzerp.com/activiti-rest/service/',
  bi_url: 'http://localhost:8080/trendz-bi',
  asset_url: 'http://dev.v4.trendzerp.com/trendz-app-assets/resources',
  ws_url: 'http://dev.v4.trendzerp.com/trendz-websocket/socket',
  acm_url: 'http://dev.v4.trendzerp.com/acm-web'
};

export const appVersion = '4.4.0';
